import * as React from "react"

// markup
const IndexPage = () => {
  return (
    <main>
      <title>Home Page</title>
      <h1>
        Chosen Solutions
      </h1>
      <p>
        Hello World!
      </p>
    </main>
  )
}

export default IndexPage
